
import React, {useState} from "react";
import Slider from "react-slick";
import './style.css'
import phone from "../../../../assets/mobile.png"
import {FaArrowLeft, FaArrowRight} from "react-icons/fa";
import {
    digitalMarketing,
    digitalMarketingServices,
    serviceContent,
    ServicegameDevelopment,
} from "../../../../constantFiles/constants";
import {log} from "util";


// map.set("AR FILTERS", arFilters);
// map.set("GAME DEVELOPMENT", gameDevelopment);
// map.set("WEB DEVELOPMENT", webDevelopment);


const NextArrow = ({onClick}: any) => {
    return (
        <div className="arrow next">
            <FaArrowRight onClick={onClick}/>
        </div>
    );
};

const PrevArrow = ({onClick}: any) => {
    return (
        <div className="arrow prev">
            <FaArrowLeft onClick={onClick}/>
        </div>
    );
};

export function GameServiceSlider(props: any) {
    console.log(props.sliderValue);

    const [imageIndex, setImageIndex] = useState(0);

    const settings = {
        infinite: true,
        // variableWidth: true,
        speed: 300,
        slidesToShow: 5,
        centerMode: true,
        slidesToScroll: 1,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                variableWidth: true,
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipeToSlide: true,

                }
            }
        ],
        beforeChange: (current: any, next: any) => setImageIndex(next),
    };


    return (
        <div className="service-slider">
                        <h1 className='latest'>LATEST WORKS</h1>

            <Slider {...settings}>
                {ServicegameDevelopment.map(({image,title,description,link,alt,id}) => (
                    <div key={id} className={id === imageIndex ? "slide activeSlide" : "slide"}>
                        <img className='cover' src={phone} alt={'cover'}/>

                        <div className="card-div">
                            <img src={image} alt={alt} loading="lazy"/>
                            <h1>{title}</h1>
                            <p>{description}</p>
                            <a href={link} target={'_blank'}> <button className='slide-button'>View</button></a>
                        </div>

                    </div>

                ))}
            </Slider>
        </div>
    )
}








