import './style.scss'
import Card from "../../components/card/Card";
import {serviceCardConst} from "../../../../constantFiles/constants";
import GameservicesCard from "../../../gameServicePage/components/servicesCard/GameservicesCard";
import RewardsservicesCard from "../../components/servicesCard/rewardsservicesCard";


export default function CardSection() {
    return (
        <section className="serviceCardSection">

            <div className="serviceCardSection_heading">
                <h1>our REWARDS & PROMOTIONS services</h1>
            </div>
            <div className="r-serviceCardContent">
                {
                    serviceCardConst.map(({heading, calssNames, content, secoundHeading}) => (
                        <RewardsservicesCard title={heading} secondTitle={secoundHeading} alt={''} classh2={calssNames} classh1={''}
                                          description={content}
                                          image={''}/>
                    ))
                }
            </div>

        </section>
    )
}