import './style.scss'
import service_image from '../../../../assets/servicePage/heropage/service_img.png'


export default function DigitalMHeroSection() {
    return (
        <section className='service-heroSection'>
            <div className="service-main">
            <div className="service-heroSection_left">
                <h1>DIGITAL MARKETING</h1>
                <h2>Elevating Brands Through Digital Connectivity.</h2>
            </div>
            <div className="service-heroSection_right">
                <img src={service_image}/>
            </div>
            </div>


        </section>
    )
}