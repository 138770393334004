import './style.scss'
import burgerking from "../../../../assets/homePage/completeProject/burgerking.svg";
import cocacola from "../../../../assets/homePage/completeProject/coke.svg";
import hbo from "../../../../assets/homePage/completeProject/hbo.svg";
import itc from "../../../../assets/homePage/completeProject/itc.svg";
import cadbury from "../../../../assets/homePage/completeProject/cadbury.svg";
import swiggy from "../../../../assets/homePage/completeProject/swigg.svg";



export default function ProductOurClients() {

    return(
        <>
            <div className='client-heading'>Our Clients</div>
        <div className='service-slide-div'>
            <div className='slide-items'>
                <img src={burgerking} alt={"burgerking"}/>
                <img src={cocacola}alt={"cocacola"}/>
                <img src={hbo}alt={hbo}/>
                <img src={itc}alt={itc}/>
                <img src={cadbury}alt={"cadbury"}/>
                <img src={swiggy}alt={"swiggy"}/>
            </div>
            <div className='slide-items'>
                <img src={burgerking} alt={"burgerking"}/>
                <img src={cocacola}alt={"cocacola"}/>
                <img src={hbo}alt={hbo}/>
                <img src={itc}alt={itc}/>
                <img src={cadbury}alt={"cadbury"}/>
                <img src={swiggy}alt={"swiggy"}/>
            </div>
        </div>
        </>
    )
}