import './style.scss'
import GameCardSection from "./sections/cardSection/GameCardSection";
import GameHeroSection from "./sections/heroSection/GameHeroSection";
import {GameServiceSlider} from "./components/serviceSlider/GameServiceSlider";
import GameOurClients from "./sections/ourClints/GameOurClients";
import {Helmet} from "react-helmet";


export default function GameServices() {

    return(
        <section className="Services">
            <Helmet>
                <title>Game Services | Octech Digital - Pioneering Digital Technology Solutions</title>
            </Helmet>
            <GameHeroSection/>
            <GameCardSection/>
            <GameServiceSlider/>
            <GameOurClients/>
        </section>
    )
}