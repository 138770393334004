import './style.scss'
import InternetThingCard from "../../components/card/InternetThingCard";
import { internetserviceCardConst} from "../../../../constantFiles/constants";
import RewardsservicesCard from "../../../servicepage/components/servicesCard/rewardsservicesCard";


export default function InternetThingCardSection() {
    return (
        <section className="serviceCardSection">

            <div className="serviceCardSection_heading">
                <h1>our INTERNET OF THINGS services</h1>
            </div>
            <div className="designserviceCardContent">
                {
                    internetserviceCardConst.map(({heading, calssNames, content, secoundHeading}) => (
                        // <InternetThingCard heading={heading} img={img} calssNames={calssNames} img2={img2}
                        //                    secoundHeading={secoundHeading} content={content}/>
                        <RewardsservicesCard title={heading} secondTitle={secoundHeading} alt={''} classh2={calssNames} classh1={''}
                                             description={content}
                                             image={''}/>
                    ))
                }
            </div>

        </section>
    )
}