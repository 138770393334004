import './style.scss'
import DesignCard from "../../components/card/DesignCard";
import {DesignserviceCardConst} from "../../../../constantFiles/constants";
import digital from "../../../../assets/homePage/servicesProvide/Digital.svg";
import ServicesCard from "../../../homePage/components/servicesCard/servicesCard";
import DesignservicesCard from "../../components/servicesCard/DesignservicesCard";


export default function DesignCardSection() {
    return (
        <section className="serviceCardSection">

            <div className="serviceCardSection_heading">
                <h1>our DESIGN services</h1>
            </div>
            <div className="designserviceCardContent">
                {
                    DesignserviceCardConst.map(({heading, calssNames, content, secoundHeading}) => (
                        // <DigitalMCard heading={heading} img={img} calssNames={calssNames} img2={img2}
                        //             secoundHeading={secoundHeading} content={content}/>
                        <DesignservicesCard title={heading} secondTitle={secoundHeading} alt={''} classh2={calssNames} classh1={''}
                                      description={content}
                                      image={''}/>
                    ))
                }
            </div>

        </section>
    )
}