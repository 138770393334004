import './style.scss'
import ProductCardSection from "./sections/cardSection/ProductCardSection";
import ProductHeroSection from "./sections/heroSection/ProductHeroSection";
import {ProductServiceSlider} from "./components/serviceSlider/ProductServiceSlider";
import ProductOurClients from "./sections/ourClints/ProductOurClients";
import {Helmet} from "react-helmet";


export default function ProductServices() {

    return(
        <section className="Services">
            <Helmet>
                <title>Product Services | Octech Digital - Pioneering Digital Technology Solutions</title>
            </Helmet>
            <ProductHeroSection/>
            <ProductCardSection/>
            {/*<ServiceDescription/>*/}
            <ProductServiceSlider/>
            {/*<QnaDropdown/>*/}
            <ProductOurClients/>
        </section>
    )
}