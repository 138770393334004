import './style.scss'
import ProductCard from "../../components/card/ProductCard";
import {GameserviceCardConst, productserviceCardConst} from "../../../../constantFiles/constants";
import DesignservicesCard from "../../../designServicesPage/components/servicesCard/DesignservicesCard";
import ProductservicesCard from "../../components/servicesCard/ProductservicesCard";


export default function ProductCardSection() {
    return (
        <section className="serviceCardSection">

            <div className="serviceCardSection_heading">
                <h1>our PRODUCT services</h1>
            </div>
            <div className="gameserviceCardContent">
                {
                    productserviceCardConst.map(({heading, calssNames, content, secoundHeading}) => (
                        // <DevelopmentCard heading={heading} img={img} calssNames={calssNames} img2={img2}
                        //           secoundHeading={secoundHeading} content={content}/>
                        <ProductservicesCard title={heading} secondTitle={secoundHeading} alt={''} classh2={calssNames} classh1={''}
                                             description={content}
                                             image={''}/>
                    ))
                }
            </div>

        </section>
    )
}