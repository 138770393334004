import "../../globalScss/global.scss";
import "./assets/scss/styles.scss";
import logo from "../../assets/homePage/heroPage/hero_logo.png";
import { useEffect, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { HiOutlineArrowUpRight } from "react-icons/hi2";
import { HiMenu } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../../lib/consts";
import CaretLeft from "../../assets/CaretLeft.png";
import "./content/style.scss";

export default function NavBarComponent() {
    const [hoverActive, setHoverActive] = useState(true);
    console.log({ hoverActive });
    const handleClick = () => {
        console.log("handleClick ", hoverActive);
        setHoverActive(!hoverActive);
    };
    const enableSubMenu = () => {
        console.log("enable ", hoverActive);
        setHoverActive(true);
    };
    const onTouchEnd = () => {
        //'nav-toggle', 'nav-menu'
        const toggle = document.getElementById("nav-toggle");
        const nav = document.getElementById("nav-menu");
        nav?.classList.toggle("show-menu");
        toggle?.classList.toggle("show-icon");
    };
    const onMouseEnter = () => {
        console.log("onMouse");
        setHoverActive(true);
        onTouchEnd();
    };

    const hideMenu = () => {
        setHoverActive(false);
    };

    const onTouchStart = () => {
        if (hoverActive) {
            hideMenu();
        } else {
            setHoverActive(true);
        }
    };

    useEffect(() => {
        const showMenu = (toggleId: string, navId: string) => {
            const toggle = document.getElementById(toggleId);
            const nav = document.getElementById(navId);

            toggle?.addEventListener("click", () => {
                // Add show-menu class to nav-menu
                nav?.classList.toggle("show-menu");
                // Add show-icon to show and hide menu icon
                toggle?.classList.toggle("show-icon");
            });
        };

        showMenu("nav-toggle", "nav-menu");

        const dropdownItems = document.querySelectorAll(".dropdown__item");

        dropdownItems.forEach((item) => {
            const dropdownButton = item.querySelector(".dropdown__button");

            dropdownButton?.addEventListener("click", () => {
                const showDropdown = document.querySelector(".show-dropdown");
                toggleItem(item);

                if (showDropdown && showDropdown !== item) {
                    toggleItem(showDropdown as HTMLElement);
                }
            });
        });

        const toggleItem = (item: Element) => {
            const dropdownContainer = item.querySelector(
                ".dropdown__container"
            ) as HTMLElement;

            if (item.classList.contains("show-dropdown")) {
                dropdownContainer.removeAttribute("style");
                item.classList.remove("show-dropdown");
            } else {
                dropdownContainer.style.height = `${dropdownContainer.scrollHeight}px`;
                item.classList.add("show-dropdown");
            }
        };

        const mediaQuery = window.matchMedia("(min-width: 1024px)");
        const dropdownContainer = document.querySelectorAll(".dropdown__container");

        const removeStyle = () => {
            if (mediaQuery.matches) {
                dropdownContainer.forEach((e) => {
                    e.removeAttribute("style");
                });

                dropdownItems.forEach((e) => {
                    e.classList.remove("show-dropdown");
                    console.log("Removed");
                });
            }
        };

        window.addEventListener("resize", removeStyle);

        return () => {
            window.removeEventListener("resize", removeStyle);
        };
    }, []);

    const location = useLocation();
    if (
        [ROUTES.CONTACTUS_PAGE, ROUTES.CAREERS_PAGE, ROUTES.MAINSERVICE_PAGE].includes(location.pathname) &&
        window.innerWidth > 600
    )
        return null;
    return (
        <header className={`header ${hoverActive ? "hover-active" : ""}`}>
            <nav className="nav container">
                <div className="nav__data">
                    <a href="#" className="nav__logo">
                        <Link to={"/"}>
                            <img src={logo} alt="logo" />
                        </Link>
                    </a>

                    <div className="nav__toggle" id="nav-toggle" onClick={handleClick}>
                        <i className="nav__toggle-menu">
                            <HiMenu />
                        </i>
                        <i className="nav__toggle-close">
                            <RxCross2 />
                        </i>
                    </div>
                </div>

                {/*NAV MENU */}

                <div className="nav__menu" id="nav-menu">
                    <ul className="nav__list">
                        {/*DROPDOWN 1*/}
                        <li className="dropdown__item" onMouseOver={enableSubMenu}>
                            <div className="nav__link dropdown__button">
                                SERVICES{" "}
                                <span className="dropdown__arrow" id={"arrow"}>
                  {" "}
                                    <RiArrowDropDownLine />
                </span>
                            </div>
                            {hoverActive ? (
                                <div className={"dropdown__container"}>
                                    <div className={"dropdown__content"}>
                                        {/*//game*/}
                                        <div className="dropdown__group">
                                            <div
                                                className="dropdown__list-heading"
                                                onClick={handleClick}
                                            >
                                                <h5>
                                                    <Link
                                                        onClick={onTouchEnd}
                                                        to={"/gameservices"}
                                                        className="dropdown__link nav-heading"
                                                    >
                                                        Game Development
                                                    </Link>
                                                </h5>{" "}
                                                <HiOutlineArrowUpRight />
                                            </div>
                                            <ul className="dropdown__list">
                                                <li>
                                                    <span>Hypercasual games</span>
                                                </li>
                                                <li>
                                                    <span>AR games</span>
                                                </li>
                                                <li>
                                                    <span>Web Games</span>
                                                </li>
                                            </ul>
                                        </div>

                                        {/*//reward*/}
                                        <div className="dropdown__group">
                                            <div
                                                className="dropdown__list-heading"
                                                onClick={handleClick}
                                            >
                                                <h5>
                                                    <Link
                                                        onClick={onTouchEnd}
                                                        to={"/services"}
                                                        className="dropdown__link nav-heading"
                                                    >
                                                        REWARDS & PROMOTIONS
                                                    </Link>
                                                </h5>{" "}
                                                <HiOutlineArrowUpRight />
                                            </div>
                                            <ul className="dropdown__list">
                                                <li>
                                                    <span>Loyalty Campaigns</span>
                                                </li>
                                                <li>
                                                    <span>Brand Promotions</span>
                                                </li>
                                                <li>
                                                    <span>Transient Impact Microsites</span>
                                                </li>
                                            </ul>
                                        </div>

                                        {/*//design*/}
                                        <div className="dropdown__group">
                                            <div
                                                className="dropdown__list-heading"
                                                onClick={handleClick}
                                            >
                                                <h5>
                                                    <Link
                                                        onClick={onTouchEnd}
                                                        to={"/DesignServices"}
                                                        className="dropdown__link nav-heading"
                                                    >
                                                        Design
                                                    </Link>
                                                </h5>{" "}
                                                <HiOutlineArrowUpRight />
                                            </div>
                                            <ul className="dropdown__list">
                                                <li>
                                                    <span>Graphic Design</span>
                                                </li>
                                                <li>
                                                    <span>Interactive Videos</span>
                                                </li>
                                                <li>
                                                    <span>Motion Graphics</span>
                                                </li>

                                                <li>
                                                    <span>3D Modelling</span>
                                                </li>
                                            </ul>
                                        </div>

                                        {/*//Product*/}
                                        <div className="dropdown__group">
                                            <div
                                                className="dropdown__list-heading"
                                                onClick={handleClick}
                                            >
                                                <h5>
                                                    <Link
                                                        onClick={onTouchEnd}
                                                        to={"/ProductServices"}
                                                        className="dropdown__link nav-heading"
                                                    >
                                                        Product
                                                    </Link>
                                                </h5>{" "}
                                                <HiOutlineArrowUpRight />
                                            </div>
                                            <ul className="dropdown__list">
                                                <li>
                                                    <span>Playvera</span>
                                                </li>
                                                <li>
                                                    <span>Sensrtag</span>
                                                </li>
                                                <li>
                                                    <span>Analytics Genie</span>
                                                </li>
                                            </ul>
                                        </div>

                                        {/*//web*/}
                                        <div className="dropdown__group">
                                            <div
                                                className="dropdown__list-heading"
                                                onClick={handleClick}
                                            >
                                                <h5>
                                                    <Link
                                                        onClick={onTouchEnd}
                                                        to={"/DevelopmentServices"}
                                                        className="dropdown__link nav-heading"
                                                    >
                                                        APP/WEB DEVELOPMENT
                                                    </Link>
                                                </h5>{" "}
                                                <HiOutlineArrowUpRight />
                                            </div>
                                            <ul className="dropdown__list">
                                                <li>
                                                    <span>AI Development</span>
                                                </li>
                                                <li>
                                                    <span>AI Chatbot</span>
                                                </li>
                                                <li>
                                                    <span>ChatGPT Models</span>
                                                </li>
                                                <li>
                                                    <span>AI Image Creation</span>
                                                </li>
                                            </ul>
                                        </div>

                                        {/*//DIGITAL*/}
                                        <div className="dropdown__group">
                                            <div
                                                className="dropdown__list-heading"
                                                onClick={handleClick}
                                            >
                                                <h5>
                                                    <Link
                                                        onClick={onTouchEnd}
                                                        to={"/DigitalMServices"}
                                                        className="dropdown__link nav-heading"
                                                    >
                                                        DIGITAL MARKETING
                                                    </Link>
                                                </h5>{" "}
                                                <HiOutlineArrowUpRight />
                                            </div>
                                            <ul className="dropdown__list">
                                                <li>
                                                    <span>Amplify Brand Awareness</span>
                                                </li>
                                                <li>
                                                    <span>Craft engaging brand strategies</span>
                                                </li>
                                                <li>
                                                    <span>Masterful Social Media management</span>
                                                </li>
                                                <li>
                                                    <span>Enhance brand image</span>
                                                </li>
                                            </ul>
                                        </div>

                                        {/*//Internet*/}
                                        <div className="dropdown__group" onClick={handleClick}>
                                            <div className="dropdown__list-heading">
                                                <h5>
                                                    <Link
                                                        onClick={onTouchEnd}
                                                        to={"/InternetServices"}
                                                        className="dropdown__link nav-heading"
                                                    >
                                                        Internet of things
                                                    </Link>
                                                </h5>{" "}
                                                <HiOutlineArrowUpRight />
                                            </div>
                                            <ul className="dropdown__list">
                                                <li>
                                                    <span>Smart home / cities software</span>
                                                </li>
                                                <li>
                                                    <span>Device Management</span>
                                                </li>
                                                <li>
                                                    <span>Healthcare</span>
                                                </li>
                                                <li>
                                                    <span></span>
                                                </li>
                                            </ul>
                                        </div>

                                        {/*//cyber*/}
                                        <div className="dropdown__group" onClick={handleClick}>
                                            <div className="dropdown__list-heading">
                                                <h5>
                                                    <Link
                                                        onClick={onTouchEnd}
                                                        to={"/SecurityServices"}
                                                        className="dropdown__link nav-heading"
                                                    >
                                                        CYBER SECURITY
                                                    </Link>
                                                </h5>{" "}
                                                <HiOutlineArrowUpRight />
                                            </div>
                                            <ul className="dropdown__list">
                                                <li>
                                                    <span>Managed Soc</span>
                                                </li>
                                                <li>
                                                    <span>App Security </span>
                                                </li>
                                                <li>
                                                    <span>Vulnerability Assesment</span>
                                                </li>

                                                <li>
                                                    <span>Governance Risk Compliance (GRC)</span>
                                                </li>
                                                <li>
                                                    <span className="zero-trust">Zero Trust</span>
                                                </li>
                                            </ul>
                                        </div>

                                        {/*//cloud*/}
                                        <div className="dropdown__group" onClick={handleClick}>
                                            <div className="dropdown__list-heading">
                                                <h5>
                                                    <Link
                                                        onClick={onTouchEnd}
                                                        to={"/CloudServices"}
                                                        className="dropdown__link nav-heading"
                                                    >
                                                        Cloud Integration
                                                    </Link>
                                                </h5>{" "}
                                                <HiOutlineArrowUpRight />
                                            </div>
                                            <ul className="dropdown__list" onClick={handleClick}>
                                                <li>
                                                    <span>Strategy Consultation</span>
                                                </li>
                                                <li>
                                                    <span>Application Modernisation</span>
                                                </li>
                                                <li>
                                                    <span>Start Up Consultancy</span>
                                                </li>
                                                <li>
                                                    <span>Managed Cloud Services</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <span />
                            )}
                        </li>

                        <li>
                            <Link to="/ourworks" className="nav__link" onClick={onTouchEnd}>
                                OUR WORK
                            </Link>
                        </li>

                        <li>
                            <Link to="/aboutus" className="nav__link" onClick={onTouchEnd}>
                                ABOUT US
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={ROUTES.CONTACTUS_PAGE}
                                className="nav__link" id="nav__link"
                                onClick={onTouchEnd}
                            >
                                CONTACT
                            </Link>
                        </li>
                        {/*<li>*/}
                        {/*    <Link*/}
                        {/*        to={ROUTES.CAREERS_PAGE}*/}
                        {/*        className="nav__link"*/}
                        {/*        onClick={onTouchEnd}*/}
                        {/*    >*/}
                        {/*        CAREERS*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                    </ul>
                </div>
                <div>
                    <Link
                        to={ROUTES.CONTACTUS_PAGE}
                        className="nav-contact-btn"
                        onClick={onTouchEnd}
                    >
                        CONTACT US
                    </Link>
                </div>
            </nav>
        </header>
    );
}