import './style.scss'
import SecurityCardSection from "./sections/cardSection/SecurityCardSection";
import SecurityHeroSection from "./sections/heroSection/SecurityHeroSection";
import {SecurityServiceSlider} from "./components/serviceSlider/SecurityServiceSlider";
import SecurityOurClients from "./sections/ourClints/SecurityOurClients";
import {Helmet} from "react-helmet";


export default function SecurityServices() {

    return(
        <section className="Services">
            <Helmet>
                <title>Security Services | Octech Digital - Pioneering Digital Technology Solutions</title>
            </Helmet>
            <SecurityHeroSection/>
            <SecurityCardSection/>
            {/*<ServiceDescription/>*/}
            <SecurityServiceSlider/>
            {/*<QnaDropdown/>*/}
            <SecurityOurClients/>
        </section>
    )
}