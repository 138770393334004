import './style.scss'
import CloudCard from "../../components/card/CloudCard";
import {cloudserviceCardConst} from "../../../../constantFiles/constants";
import DesignservicesCard from "../../../designServicesPage/components/servicesCard/DesignservicesCard";


export default function CloudCardSection() {
    return (
        <section className="serviceCardSection">

            <div className="serviceCardSection_heading">
                <h1>our CLOUD INTEGRATION services</h1>
            </div>
            <div className="designserviceCardContent">
                {
                    cloudserviceCardConst.map(({heading, calssNames, content, secoundHeading}) => (
                        // <CloudCard heading={heading} img={img} calssNames={calssNames} img2={img2}
                        //            secoundHeading={secoundHeading} content={content}/>
                        <DesignservicesCard title={heading} secondTitle={secoundHeading} alt={''} classh2={calssNames} classh1={''}
                                            description={content}
                                            image={''}/>
                    ))
                }
            </div>

        </section>
    )
}