import './style.scss'
import {useState} from "react";
import {PushSpinner, TraceSpinner} from "react-spinners-kit";


export  default function Spinner() {
    const [loading, setLoading] =useState(true)
    console.log("spinnig?")
    return(
        <div className="spinnerContainer">
            <div className="spinner">
                <PushSpinner size={30} color="#FF2157"
                             loading={loading} />
            </div>

        </div>
    )

}