import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/global.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import {BrowserRouter, BrowserRouter as Router} from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import { GlobalLoader } from "./helpers/GlobalLoader";
import {Helmet} from "react-helmet";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>

    <Provider store={store}>
      {/*<BrowserRouter basename={"/"}>*/}
      <BrowserRouter basename={"/"}>
          {/*<Helmet>*/}
          {/*    <html lang="en" />*/}
          {/*    <meta name="" title=""/>*/}
          {/*    <meta name="description" content="We are a cross-channel technology services firm, with a team of young,*/}
          {/*  talented, experienced go-getters determined to revolutionize the digital technology space by creating*/}
          {/*  trendsetting Gamification Experiences,  enchanting Augmented Reality campaigns, sophisticated Generative AI Models,*/}
          {/*   and world-class, custom-built tech products" />*/}
          {/*    <meta name={"keyword"} content={"instagram filter, game filter "}/>*/}
          {/*</Helmet>*/}
        <ScrollToTop />
        <GlobalLoader>
          <App />
        </GlobalLoader>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
