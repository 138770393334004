import {
    cadburry,
    coke_web,
    dark_fantasy,
    darkFantasy, galaxy, galaxy_web,
    gillet, graphic_design,
    holi,
    indimint,
    jemsCricket,
    magicSlot, milkbikies, mint,
    moneyView, motion_graphic,
    nevia, orient,
    phillips, product_design, promotional_video, rabta, sandisk,
    smartBreak, sunfeast,
    swiggy,
    tcl, threeD_design, tic_tac, ticTak, ui_ux,

} from "./images";

export const portfolioList = [
    {
        percent: 10,
        title: 'REWARDS & PROMOTIONS'
    },
    {   percent: 40,
        title: 'AR FILTERS'
    },
    {
        percent: 65,
        title: 'GAME DEVELOPMENT'
    },
    {
        percent: 94,
        title: 'WEB DEVELOPMENT'
    }
];


export const serviceCardConst = [

    {
        heading: 'Loyalty Campaigns',
        img: ui_ux,
        calssNames: '',
        img2: '',
        secoundHeading:'Loyalty Campaigns',
        content: 'We are dedicated to delivering impactful loyalty campaigns to our clients.  Our commitment lies in crafting tailored rewards and promotions initiatives that resonate with the audience, fostering lasting connections and elevating your brand\'s loyalty strategy.',

    },
    {
        heading: 'Brand Promotions',
        img: graphic_design,
        calssNames: '',
        img2: '',
        secoundHeading:'Brand Promotions',
        content: 'We specialise in crafting customised campaigns that highlight your brand’s uniqueness, ensuring that your message resonates with your target audience and creates a lasting impact.',

    },
    {
        heading: 'Transient Impact Microsites',
        img: motion_graphic,
        calssNames: '',
        img2: '',
        secoundHeading:'Transient Impact Microsites',
        content:'With our limited time microsites, your brand gains heightened visibility, engagement and a strategic edge in the competitive world.',

    },
    // {
    //     heading: '3D DESIGN',
    //     img: threeD_design,
    //     calssNames: '',
    //     img2: '',
    //
    // },
    // {
    //     heading: 'PRODUCT DESIGN',
    //     img: product_design,
    //     calssNames: '',
    //     img2: '',
    //
    // }
]

//service Game :

export const GameserviceCardConst = [

    {
        heading: 'HYPER CASUAL GAMES',
        img: ui_ux,
        calssNames: '',
        img2: '',
        secoundHeading:'HYPER CASUAL GAMES',
        content: 'We craft hyper casual games with intuitive gameplay, captivating visuals, and data-driven refinement. Our agile approach prioritises player engagement through rapid iteration, cross-promotion, and optimised performance, ensuring a seamless and enjoyable gaming experience.',

    },
    {
        heading: 'AR GAMES',
        img: graphic_design,
        calssNames: '',
        img2: '',
        secoundHeading:'AR GAMES',
        content: 'We\'re experts at creating engaging AR experiences through innovative technology and creative design. Our focus is on seamless integration, user engagement, and maximising AR capabilities for unforgettable gaming adventures.',

    },
    {
        heading: 'WEB GAMES',
        img: motion_graphic,
        calssNames: '',
        img2: '',
        secoundHeading:'WEB GAMES',
        content:'Our speciality,  we create engaging web games with user-friendly interfaces, seamless performance, and cross-device compatibility. Our focus on creative design ensures captivating experiences that cater to diverse players.',

    },

]

export const DesignserviceCardConst = [

    {
        heading: 'Graphic design',
        img: ui_ux,
        calssNames: '',
        img2: '',
        secoundHeading:'Graphic design',
        content: 'Our goal is to craft web applications with effective UI/UX that builds customer confidence and fosters repeat engagement. By focusing on intuitive and visually appealing designs, we strive to provide an exceptional user experience that keeps customers coming back for more.',

    },
    {
        heading: 'Interactive Videos',
        img: graphic_design,
        calssNames: '',
        img2: '',
        secoundHeading:'Interactive Videos',
        content: 'Interactive videos are like personalised adventures where you can make choices and influence the story. With interactive videos, you\'re not just watching, but actively engaging with the content. It\'s like a game and a movie rolled into one, giving you a unique and unforgettable experience. Get ready to take your video-consumption to the next level.',

    },
    {
        heading: 'Motion Graphics',
        img: motion_graphic,
        calssNames: '',
        img2: '',
        secoundHeading:'Motion Graphics',
        content:'Motion graphics is the art of bringing static elements to life with captivating animations and effects, like a visual symphony that grabs attention and leaves a lasting impression. Get ready to infuse your content with dynamic energy and make it unforgettable with our motion graphics',

    },
    {
        heading: '3D Modelling',
        img: motion_graphic,
        calssNames: '',
        img2: '',
        secoundHeading:'3D Modelling',
        content:'3D is the art of creating digital masterpieces, from intricate objects to breath-taking landscapes. Whether you\'re designing video game characters, architectural marvels, or futuristic gadgets, 3D modelling lets you unleash your creativity and make your imagination tangible. It\'s like having a superpower to transform concepts into visually stunning realities.',

    },

]
export const cloudserviceCardConst = [

    {
        heading: 'Strategy and Consultation',
        img: ui_ux,
        calssNames: '',
        img2: '',
        secoundHeading:'Strategy and Consultation',
        content: 'Our collaborative approach involves deeply understanding your organization\'s goals, challenges, and aspirations. We craft tailored strategies that align with your vision, ensuring that each step is meticulously planned and executed.',

    },
    {
        heading: 'Application Modernisation',
        img: graphic_design,
        calssNames: '',
        img2: '',
        secoundHeading:'Application Modernisation',
        content: 'Revitalise your digital landscape with our Application Modernization services. We transform legacy systems into agile, scalable, and future-ready solutions.',
    },
    {
        heading: 'Start Up Consultancy',
        img: motion_graphic,
        calssNames: '',
        img2: '',
        secoundHeading:'Start Up Consultancy',
        content:'Our Startup Consultancy services provide tailored strategies, market insights, and actionable steps to set your venture on the path to success.',

    },
    {
        heading: 'Managed Cloud Services',
        img: motion_graphic,
        calssNames: '',
        img2: '',
        secoundHeading:'Managed Cloud Services',
        content:'We handle the complexities of cloud infrastructure, security, and optimization, ensuring your systems run seamlessly. Focus on your core business while we manage your cloud environment, enabling scalability, cost-efficiency, and uninterrupted performance.',

    },

]

export const securityserviceCardConst = [

    {
        heading: 'Managed Soc',
        img: ui_ux,
        calssNames: '',
        img2: '',
        secoundHeading:'Managed Soc',
        content: 'Through Managed Soc providers we offer organisations external cybersecurity experts who monitor their devices and network for threats.',

    },
    {
        heading: 'Governance Risk Compliance (GRC)',
        img: motion_graphic,
        calssNames: '',
        img2: '',
        secoundHeading:'Governance Risk Compliance',
        content: 'Our GRC services encompass meticulous risk assessment, strategic policy formulation, and robust control implementations. Our goal is to empower your organisation to confidently manage risks, streamline governance, and navigate the ever-evolving compliance landscape with utmost precision.',

    },

    {
        heading: 'Vulnerability Assessment',
        img: motion_graphic,
        calssNames: '',
        img2: '',
        secoundHeading:'Vulnerability Assessment',
        content: 'Our professional Vulnerability Assessment Service offers a meticulous and systematic analysis of your digital ecosystem. With a keen eye for potential weaknesses, our experts conduct thorough evaluations to identify vulnerabilities that could compromise your security.',

    },

    {
        heading: 'APP Security',
        img: graphic_design,
        calssNames: '',
        img2: '',
        secoundHeading:'APP Security',
        content: 'App security helps to ensure that user data is protected from unauthorised access and hacking, which gives users peace of mind and helps in building trust in the brand.',

    },

    {
        heading: 'Zero Trust',
        img: motion_graphic,
        calssNames: '',
        img2: '',
        secoundHeading:'Zero Trust',
        content: 'Embrace the future of cybersecurity with our Zero Trust Implementation services.Our Zero Trust services ensure that every interaction is subject to rigorous scrutiny, minimising the risk of unauthorised access and data breaches.',

    },

]

export const internetserviceCardConst = [

    {
        heading: 'Smart home/cities software',
        img: ui_ux,
        calssNames: '',
        img2: '',
        secoundHeading:'Smart home/cities software',
        content: 'Smart home software can provide personalised experiences based on brands behaviour and preferences. Brands can use this data to modify their messaging and their offers to the individual user making it more engaging and relevant.',

    },
    {
        heading: 'Device Management',
        img: graphic_design,
        calssNames: '',
        img2: '',
        secoundHeading:'Device Management',
        content: 'Through effective device management we can provide a seamless and positive user experience. When devices are properly managed, users are likely to experience technical issues or interruptions, which can increase their satisfaction with the product and improve their perception of the brand.',

    },
    {
        heading: 'Healthcare',
        img: motion_graphic,
        calssNames: '',
        img2: '',
        secoundHeading:'Healthcare',
        content: 'Healthcare is a personal service for the consumers as they help to gain trust and credibility among the users. This also helps improve market strategy which also helps in user satisfaction and loyalty to the brand.',

    },
    {
        heading: 'Retail/e-commerce',
        img: product_design,
        calssNames: '',
        img2: '',
        secoundHeading:'Retail/e-commerce',
        content: 'A solid e-commerce customer service can help keep the majority of the customers from leaving over something as simple as returning a product. Also, connecting emotionally with clients impacts the customer lifetime value that is four times greater than without that personal touch.',

    },

]

export const productserviceCardConst = [

    {
        heading: 'Playvera',
        img: ui_ux,
        calssNames: '',
        img2: '',
        secoundHeading:'Playvera',
        content:'At Octech, gaming isn\'t just a hobby – it\'s a passion and with our in-depth knowledge for creating addictive hypercasual games , we have meticulously crafted and launched our very own free to play gaming platform.',
    },
    {
    heading: 'Sensrtag',
    img: graphic_design,
    calssNames: '',
    img2: '',
    secoundHeading:'Sensrtag',
    content:' We tackle product authenticity challenges head-on with our simple digital solution. QR codes on each product reveal accurate production, batch, and comprehensive info, ensuring undeniable authenticity.',
},
    {
        heading: 'Analytics genie',
        img: motion_graphic,
        calssNames: '',
        img2: '',
        secoundHeading:'Analytics genie',
        content:'Our tailor-made product, created from the ground up, meets the demands of data analytics. It offers detailed insights across all parameters, fortified by end-to-end 256-bit encryption for ultimate security. Our goal is to set the industry standard.',
    },
    ]
export const digitalMarketingserviceCardConst = [

    {
        heading: 'AMPLIFY BRAND AWARENESS',
        img: ui_ux,
        calssNames: '',
        img2: '',
        secoundHeading:'AMPLIFY BRAND AWARENESS',
        content:'In the current Market landscape, brand recall is a vital necessity for staying relevant. Our approach involves crafting strategic, consumer-focused campaigns that maintain a consistently high level of brand awareness.',
    },
    {
    heading: 'CRAFT ENGAGING BRAND STRATEGIES',
    img: graphic_design,
    calssNames: '',
    img2: '',
    secoundHeading:'CRAFT ENGAGING BRAND STRATEGIES',
    content:'We figure out how to make our brand stand out by understanding our audience, telling a compelling story, using creative visuals, and picking the best online platforms.',
},
    {
        heading: 'MASTERFUL SOCIAL MEDIA MANAGEMENT',
        img: motion_graphic,
        calssNames: '',
        img2: '',
        secoundHeading:'MASTERFUL SOCIAL MEDIA MANAGEMENT',
        content:'We finely tune our posts to resonate with our audience\'s preferences, crafting a harmonious blend of content that informs, entertains, and connects.',
    },
    {
        heading: 'ENHANCE BRAND IMAGE',
        img: product_design,
        calssNames: '',
        img2: '',
        secoundHeading:'ENHANCE BRAND IMAGE',
        content:' We refine our digital masterpiece, ultimately crafting an elevated and enduring brand image that captivates and inspires.',
    },
    ]
export const appAndWebserviceCardConst = [

    {
        heading: 'AI Development',
        img: ui_ux,
        calssNames: '',
        img2: '',
        secoundHeading:'AI Development',
        content:'We develop smart solutions that enhance user experiences and operational efficiency through seamless integration of advanced technology and design that assists in future ready technology, delivered today.',
    },
    {
    heading: 'AI Chatbot',
    img: graphic_design,
    calssNames: '',
    img2: '',
    secoundHeading:'AI Chatbot',
    content:'Our Chatbot solutions spruced up by ChatGPT with its NLP helps in automating tasks and increase efficiency.',
},
    {
        heading: 'AI Image Creation',
        img: motion_graphic,
        calssNames: '',
        img2: '',
        secoundHeading:'AI Image Creation',
        content:'Let our expertise take over in the new era of Ai image creations for stand out campaigns.',
    },

    ]



export const navContentConst = [
    {
        heading: 'Graphic design',
        content:'Our goal is to craft web applications with effective UI/UX\n' +
            'that builds customer confidence and fosters repeat engagement. By focusing\n' +
            'on intuitive and visually appealing designs, we strive to provide an exceptional\n' +
            'user experience that keeps customers coming back for more.',
        idName:'graphicDesign',
    },
    {
        heading: 'Interactive Videos',
        content:'Interactive videos are like personalized adventures\n' +
            'where you can make choices and influence the story. With interactive videos,\n' +
            'you&#39;re not just watching, but actively engaging with the content. It&#39;s like a\n' +
            'game and a movie rolled into one, giving you a unique and unforgettable\n' +
            'experience. Get ready to take your video-consumption to the next level.',
        idName:'interactiveVideos',
    },
    {
        heading: 'Motion Graphics',
        content:'Motion graphics is the art of bringing static elements to life with captivating\n' +
            'animations and effects, like a visual symphony that grabs attention and leaves\n' +
            'a lasting impression. Get ready to infuse your content with dynamic energy\n' +
            'and make it unforgettable with our motion graphics.',
        idName:'motionGraphics',
    },
    {
        heading: '3D Modelling',
        content:'3D is the art of creating digital masterpieces, from intricate\n' +
            'objects to breath-taking landscapes. Whether you&#39;re designing video game\n' +
            'characters, architectural marvels, or futuristic gadgets, 3D modelling lets you\n' +
            'unleash your creativity and make your imagination tangible. It&#39;s like having a\n' +
            'superpower to transform concepts into visually stunning realities.',
        idName:'threedModelling',
    },
    {
        heading: 'Instagram Filters',
        content:'Instagram filters play a significant role in increasing brand\n' +
            'engagement by providing a fun and interactive way for users to\n' +
            'interact with a brand.',
        idName:'instagramFilters',
    },
    {
        heading: 'Snapchat Filters',
        content:'Snapchat also helps in user activity by having the\n' +
            'default setting on the app which sends a notification every time the\n' +
            'user receives a snap.',
        idName:'snapchatFilters',
    },
    {
        heading: 'Interactive Videos',
        content:'An Interactive video is a type of video that\n' +
            'helps in boosting engagement with the help of various contents.\n' +
            'It enables the user to engage while watching while also helping\n' +
            'them to know more about their product/service.',
        idName:'interactiveVideos',
    },
    {
        heading: 'Web Games',
        content:'Gamification marketing can also improve user engagement by\n' +
            'making the user feel in control. A gamification experience will also\n' +
            'help the user enjoy the experience as they get to play and interact.',
        idName:'webGames',
    },
    {
        heading: 'Web Apps',
        content:'Through web apps we get to connect with the audience,\n' +
            'convey brand messages, which also help us keep users engaged to\n' +
            'create customer loyalty in a crowded marketplace.',
        idName:'webApps',
    },
    {
        heading: 'Microsites',
        content:'Microsites help to increase visibility by providing a unique URL that\n' +
            'can be easily shared and promoted. They also allow brands to craft\n' +
            'targeted messaging for specific audience. This helps in understanding\n' +
            'the interest and likes of the targeted audience which is also increases\n' +
            'the chances of engagement.',
        idName:'microsites',
    },
    {
        heading: 'Chatbots',
        content:'Chatbots engage customers by making them understand their\n' +
            'product better. When the customer fails to understand something,\n' +
            '\n' +'the chatbot will read the query and send them details about the\n' +
            'product and also help them in decision making.',
        idName:'chatbots',
    },
    {
        heading: 'UI/UX Design',
        content:'The UX/UI Design is the design of user interface for\n' +
            'machines and other electronic devices. We create sites with effective\n' +
            'UI/UX which helps win customer confidence and keep them coming\n' +
            'back for more.',
        idName:'uiuxDesign',
    },
    {
        heading: 'Smart home/cities software',
        content:'Smart home software can provide\n' +
            'personalised experiences based on brands behaviour and\n' +
            'preferences. Brands can use this data to modify their messaging and\n' +
            'their offers to the individual user making it more engaging and\n' +
            'relevant.',
        idName:'smartHomeCitiesSoftware',
    },
    {
        heading: 'Device Management',
        content:'Through effective device management we can provide a seamless\n' +
            'and positive user experience. When devices are properly managed,\n' +
            'users are likely to experience technical issues or interruptions, which\n' +
            'can increase their satisfaction with the product and improve their\n' +
            'perception of the brand.',
        idName:'deviceManagement',
    },
    {
        heading: 'Healthcare',
        content:'Healthcare is a personal service for the consumers as they help to\n' +
            'gain trust and credibility among the users. This also helps improve\n' +
            'market strategy which also helps in user satisfaction and loyalty to\n' +
            'the brand.',
        idName:'healthcare',
    },
    {
        heading: 'Retail/e-commerce',
        content:'A solid e-commerce customer service can help keeping majority of\n' +
            'the customers from leaving over something as simple as returning a\n' +
            '\n' + 'product. Also, by connecting emotionally with clients impacts the\n' +
            'customer lifetime value that is four times greater than without that\n' +
            'personal touch.',
        idName:'retailEcommerce',
    }

]


//service-dropdown :

export const serviceDropdown = [
    {
        heading: 'How many duration take ad into conversation ? What is seo factors ?',
        content:'An authentic cricket experience where the user gets to select their own team and enjoy the cricket experience.',

    },
    {
        heading: 'How to much charge for google ads ?',
        content:'An authentic cricket experience where the user gets to select their own team and enjoy the cricket experience.',

    },
    {
        heading: 'Basic plan annual charge ?',
        content:'An authentic cricket experience where the user gets to select their own team and enjoy the cricket experience.',

    },
    {
        heading: 'What is seo factors ?',
        content:'An authentic cricket experience where the user gets to select their own team and enjoy the cricket experience.',

    },
    {
        heading: 'How many duration take ad into conversation ?',
        content:'An authentic cricket experience where the user gets to select their own team and enjoy the cricket experience.',

    },

]



export const serviceContent = [
    {
        image:galaxy,
        heading: 'Galaxy',
        content: 'Send a personalized valentine with and upload a couple photo with our galaxy frame to stand a chance to win rewards',
        alt:'Galaxy',
        link: 'https://thedigitalapps.com/galaxyvalentine/',
        id:1,
    },
    {
        image:sunfeast,
        heading: 'Sunfeast',
        content: 'Enter the unique code on the pack and get a chance to win exciting rewards.',
        alt:'Sunfeast',
        link: 'https://thedigitalapps.com/sunfeast_milkshake/',
        id:2,

    },
    {
        image: dark_fantasy,
        heading: 'Dark Fantasy',
        content: 'Stand a chance to win PARIS trip and more exciting prizes and assured gifts.',
        alt:'Dark Fantasy',
        link: 'https://thedigitalapps.com/ITC/dark-fantasy/',
        id:3,

    },
    {
        image: tic_tac,
        heading: 'Tic Tac',
        content: 'Create your gentle message and participate in the contest by entering the code on your Tic-Tac gentle messages pack.',
        alt:'Tic Tac',
        link: 'https://thedigitalapps.com/tictac_2/#/',
        id:4,

    },
    // {
    //     image: dark_fantasy,
    //     heading: 'Dark Fantasy',
    //     content: 'Stand a chance to win PARIS trip and more exciting prizes and assured gifts.',
    //     alt:'Dark Fantasy',
    //     link: 'https://thedigitalapps.com/ITC/dark-fantasy/',
    //     id:5,
    //
    // },
    // {
    //     image:sunfeast,
    //     heading: 'Sunfeast',
    //     content: 'Enter the unique code on the pack and get a chance to win exciting rewards.',
    //     alt:'Sunfeast',
    //     link: 'https://thedigitalapps.com/sunfeast_milkshake/',
    //     id:6,
    //
    // },
    // {
    //     image:galaxy,
    //     heading: 'Galaxy',
    //     content: 'Send a personalized valentine with and upload a couple photo with our galaxy frame to stand a chance to win rewards',
    //     alt:'Galaxy',
    //     link: 'https://thedigitalapps.com/galaxyvalentine/',
    //     id:7,
    // },

]





export const arFilters = [
    {
        image: nevia,
        title: 'Nivea World Cup',
        description: 'A greetings filter with hand tracking that we created for the brand Nivea during the IPL season.' +
            ' A trophy is displayed once the user brings up their hand.',
        link: 'https://www.instagram.com/ar/1493392751151283/',
        alt: 'nevia'
    },
    {
        image: holi,
        title: 'Holi Filter',
        description: 'The quintessential Holi Splash filter where the user gets to choose the colors from the options ' +
            'displayed on screen to be splashed on their face.',
        link: 'https://www.instagram.com/ar/575263934803815/',
        alt: 'holi'
    },
    {
        image: magicSlot,
        title: 'Magic Slot',
        description: '– These types of filters add a colorful effect to your photos and videos. When the user activates ' +
            'the filter, the berries appear to be exploding from behind the user\'s face, giving the impression of a "berry blast".',
        link: 'https://www.instagram.com/ar/1281052222479069/',
        alt: 'magicSlot'
    },
    {
        image: darkFantasy,
        title: 'Dark Fantasy',
        description: 'An exciting chatbot contest, “ Find your Fantasy Cookie “ where the participants stood a chance to ' +
            'amazing prizes, including a trip for two to Paris, gold vouchers, and smartphones.',
        link: 'https://www.instagram.com/ar/399313568526679/',
        alt: 'darkFantasy'
    },
    {
        image: moneyView,
        title: 'Money View',
        description: 'An   AR vacation filter that allows users to enhance their vacation photos and videos by trying on' +
            ' virtual hats and sunglasses.',
        link: 'https://www.instagram.com/ar/6355044821249984/',
        alt: 'nevia'
    },
    {
        image: gillet,
        title: 'Venus Gillete',
        description: 'A fun AR quiz game filter where the users have to record their answers to the brand related ' +
            'questions and share their experience using the  product.',
        link: 'https://www.instagram.com/ar/411259173295450/',
        alt: 'moneyView'
    },

];


export const gameDevelopment = [
    {
        image: swiggy,
        title: 'Swiggy Santa Ride',
        description: 'An HTML game we created for delivery partners to keep the delivery app active.',
        link: 'https://thedigitalapps.com/swiggy/',
        alt: 'swiggy'
    },
    {
        image: jemsCricket,
        title: 'Gems Cricket',
        description: 'An authentic cricket experience where the user gets to select their own team and enjoy the cricket experience. ',
        link: 'https://thedigitalapps.com/gemscricket/',
        alt: 'jemsCricket'
    },
    {
        image: smartBreak,
        title: 'Smart Breaks ',
        description: 'A fun HTML game where the user has to play one of the game to find their Smartness Quotient.',
        link: 'https://thedigitalapps.com/ITC/smartbreaks/',
        alt: 'smartBreak'
    },
    {
        image: indimint,
        title: 'Indiemint Holi',
        description: 'A fun game where the user sees how many characters he can tag with the colors of holi.',
        link: 'https://thedigitalapps.com/indiemint/',
        alt: 'indimint'
    },
    {
        image: tcl,
        title: 'TCL Cricket',
        description: 'An augmented reality 8-ball game that added an extra layer of excitement by allowing players to ' +
            'enjoy the game in a real-word space.',
        link: 'https://www.8thwall.com/octechinc/tcl-cricket-game',
        alt: 'tcl'
    },
    {
        image: phillips,
        title: 'Philips 360° Game',
        description: 'A web AR application where the user has to move the device in 360 degrees to collect the hidden elements.',
        link: 'https://www.instagram.com/ar/411259173295450/',
        alt: 'phillips'
    },
    // {
    //     image: ticTak,
    //     title: 'Tic Tac Game',
    //     description: 'A web AR application where the user has to move the device in 360 degrees to collect the hidden elements.',
    //     link: 'https://www.instagram.com/ar/411259173295450/',
    //     alt: 'phillips'
    // },
    // {
    //
    //     image: sandisk,
    //     title: 'San Disk Game',
    //     description: 'A web AR application where the user has to move the device in 360 degrees to collect the hidden elements.',
    //     link: 'https://www.instagram.com/ar/411259173295450/',
    //     alt: 'phillips'
    // },

];


export const digitalMarketing = [
    {
        image: rabta,
        title: 'Raabta',
        description: 'An interesting theme puzzle web app campaign with three levels, where users solve the intricate puzzle and stand a chance to win enticing prizes.',
        link: '',
        alt: 'swiggy'
    },
    {
        image: mint,
        title: 'Mint Moments',
        description: 'A unique chatbot brand promotion campaign, where participants engage with the interactive chatbot, exploring an innovative way to connect with the brand. Through conversations, users unlocked exclusive rewards and prizes.',
        link: '',
        alt: 'jemsCricket'
    },
    {
        image: orient,
        title: 'Orient',
        description: 'A fun spin and win campaign where users scanned codes from the product for exhilarating digital spins, where luck and anticipation intertwined. Prizes were won, rewards were unlocked, and lucky participants relished in the joy of unexpected delights.',
        link: '',
        alt: 'jemsCricket'
    },
    // {
    //     image:milkbikies ,
    //     title: 'Milk Bikies',
    //     description: '',
    //     link: '',
    //     alt: 'jemsCricket'
    // },
    // {
    //     image:cadburry ,
    //     title: 'Cadbury',
    //     description: '',
    //     link: '',
    //     alt: 'jemsCricket'
    // },
    // {
    //     image:cadburry ,
    //     title: 'Cadbury',
    //     description: '',
    //     link: '',
    //     alt: 'jemsCricket'
    // },
    {
        image: rabta,
        title: 'Raabta',
        description: 'An interesting theme puzzle web app campaign with three levels, where users solve the intricate puzzle and stand a chance to win enticing prizes.',
        link: '',
        alt: 'swiggy'
    },
    {
        image: mint,
        title: 'Mint Moments',
        description: 'A unique chatbot brand promotion campaign, where participants engage with the interactive chatbot, exploring an innovative way to connect with the brand. Through conversations, users unlocked exclusive rewards and prizes.',
        link: '',
        alt: 'jemsCricket'
    },
    {
        image: orient,
        title: 'Orient',
        description: 'A fun spin and win campaign where users scanned codes from the product for exhilarating digital spins, where luck and anticipation intertwined. Prizes were won, rewards were unlocked, and lucky participants relished in the joy of unexpected delights.',
        link: '',
        alt: 'jemsCricket'
    },


];


export const webDevelopment = [
    {
        image: ticTak,
        title: 'Tic Tac',
        description: 'An engaging messenger campaign where the user gets to create a gentle message, choose the layout and also  select their favorite tic tac emoji.',
        link: 'https://thedigitalapps.com/swiggy/',
        alt: 'swiggy'
    },
    {
        image: sandisk,
        title: 'San Disk',
        description: 'An engaging campaign, called #ShareYourWorld, which was created to celebrate the power of emotion through images.',
        link: 'https://thedigitalapps.com/gemscricket/',
        alt: 'jemsCricket'
    },
    {
        image: coke_web,
        title: 'Coke Zero',
        description: 'A cutting edge microsite for the brand Coke Zero, displaying their video adds and product information.',
        link: 'https://thedigitalapps.com/ITC/smartbreaks/',
        alt: 'smartBreak'
    },
    {
        image: galaxy_web,
        title: 'Galaxy Valentine',
        description: ' Galaxy valentine campaign, where the users have to upload their photo in the galaxy frame and stand a chance to win exciting rewards.',
        link: 'https://thedigitalapps.com/indiemint/',
        alt: 'indimint'
    },
    {
        image: ticTak,
        title: 'Tic Tac',
        description: 'An engaging messenger campaign where the user gets to create a gentle message, choose the layout and also  select their favorite tic tac emoji.',
        link: 'https://thedigitalapps.com/swiggy/',
        alt: 'swiggy'
    },
    {
        image: sandisk,
        title: 'San Disk',
        description: 'An engaging campaign, called #ShareYourWorld, which was created to celebrate the power of emotion through images.',
        link: 'https://thedigitalapps.com/gemscricket/',
        alt: 'jemsCricket'
    },
    {
        image: galaxy_web,
        title: 'Galaxy Valentine',
        description: ' Galaxy valentine campaign, where the users have to upload their photo in the galaxy frame and stand a chance to win exciting rewards.',
        link: 'https://thedigitalapps.com/indiemint/',
        alt: 'indimint'
    },


];


//services:


export const digitalMarketingServices = [
    {
        image: rabta,
        title: 'Raabta',
        description: 'An interesting theme puzzle web app campaign with three levels, where users solve the intricate puzzle and stand a chance to win enticing prizes.',
        link: '',
        alt: 'swiggy',
        id: 0,
    },
    {
        image: mint,
        title: 'Mint Moments',
        description: 'A unique chatbot brand promotion campaign, where participants engage with the interactive chatbot, exploring an innovative way to connect with the brand. Through conversations, users unlocked exclusive rewards and prizes.',
        link: '',
        alt: 'jemsCricket',
        id: 1,

    },
    {
        image: orient,
        title: 'Orient',
        description: 'A fun spin and win campaign where users scanned codes from the product for exhilarating digital spins, where luck and anticipation intertwined. Prizes were won, rewards were unlocked, and lucky participants relished in the joy of unexpected delights.',
        link: '',
        alt: 'jemsCricket',
        id: 2,

    },
    // {
    //     image:milkbikies ,
    //     title: 'Milk Bikies',
    //     description: '',
    //     link: '',
    //     alt: 'jemsCricket'
    // },
    // {
    //     image:cadburry ,
    //     title: 'Cadbury',
    //     description: '',
    //     link: '',
    //     alt: 'jemsCricket'
    // },
    // {
    //     image:cadburry ,
    //     title: 'Cadbury',
    //     description: '',
    //     link: '',
    //     alt: 'jemsCricket'
    // },
    {
        image: rabta,
        title: 'Raabta',
        description: 'An interesting theme puzzle web app campaign with three levels, where users solve the intricate puzzle and stand a chance to win enticing prizes.',
        link: '',
        alt: 'swiggy',
        id: 3,

    },
    {
        image: mint,
        title: 'Mint Moments',
        description: 'A unique chatbot brand promotion campaign, where participants engage with the interactive chatbot, exploring an innovative way to connect with the brand. Through conversations, users unlocked exclusive rewards and prizes.',
        link: '',
        alt: 'jemsCricket',
        id: 4,

    },
    {
        image: orient,
        title: 'Orient',
        description: 'A fun spin and win campaign where users scanned codes from the product for exhilarating digital spins, where luck and anticipation intertwined. Prizes were won, rewards were unlocked, and lucky participants relished in the joy of unexpected delights.',
        link: '',
        alt: 'jemsCricket',
        id: 5,

    },



];

export const ServicegameDevelopment = [
    {
        image: swiggy,
        title: 'Swiggy Santa Ride',
        description: 'An HTML game we created for delivery partners to keep the delivery app active.',
        link: 'https://thedigitalapps.com/swiggy/',
        alt: 'swiggy',
        id:0,
    },
    {
        image: jemsCricket,
        title: 'Gems Cricket',
        description: 'An authentic cricket experience where the user gets to select their own team and enjoy the cricket experience. ',
        link: 'https://thedigitalapps.com/gemscricket/',
        alt: 'jemsCricket',
        id:1,

    },
    {
        image: smartBreak,
        title: 'Smart Breaks ',
        description: 'A fun HTML game where the user has to play one of the game to find their Smartness Quotient.',
        link: 'https://thedigitalapps.com/ITC/smartbreaks/',
        alt: 'smartBreak',
        id:2,

    },
    {
        image: indimint,
        title: 'Indiemint Holi',
        description: 'A fun game where the user sees how many characters he can tag with the colors of holi.',
        link: 'https://thedigitalapps.com/indiemint/',
        alt: 'indimint',
        id:3,

    },
    {
        image: tcl,
        title: 'TCL Cricket',
        description: 'An augmented reality 8-ball game that added an extra layer of excitement by allowing players to ' +
            'enjoy the game in a real-word space.',
        link: 'https://www.8thwall.com/octechinc/tcl-cricket-game',
        alt: 'tcl',
        id:4,

    },
    {
        image: phillips,
        title: 'Philips 360° Game',
        description: 'A web AR application where the user has to move the device in 360 degrees to collect the hidden elements.',
        link: 'https://www.instagram.com/ar/411259173295450/',
        alt: 'phillips',
        id:5,

    },
    // {
    //     image: ticTak,
    //     title: 'Tic Tac Game',
    //     description: 'A web AR application where the user has to move the device in 360 degrees to collect the hidden elements.',
    //     link: 'https://www.instagram.com/ar/411259173295450/',
    //     alt: 'phillips'
    // },
    // {
    //
    //     image: sandisk,
    //     title: 'San Disk Game',
    //     description: 'A web AR application where the user has to move the device in 360 degrees to collect the hidden elements.',
    //     link: 'https://www.instagram.com/ar/411259173295450/',
    //     alt: 'phillips'
    // },

];




