import './style.scss'
import DevelopmentCardSection from "./sections/cardSection/DevelopmentCardSection";
import DevelopmentHeroSection from "./sections/heroSection/DevelopmentHeroSection";
import {DevelopmentServiceSlider} from "./components/serviceSlider/DevelopmentServiceSlider";
import DevelopmentOurClients from "./sections/ourClints/DevelopmentOurClients";
import {Helmet} from "react-helmet";


export default function DevelopmentServices() {

    return(
        <section className="Services">
            <Helmet>
                <title>Development Services | Octech Digital - Pioneering Digital Technology Solutions</title>
            </Helmet>
            <DevelopmentHeroSection/>
            <DevelopmentCardSection/>
            {/*<ServiceDescription/>*/}
            <DevelopmentServiceSlider/>
            {/*<QnaDropdown/>*/}
            <DevelopmentOurClients/>
        </section>
    )
}