import './style.scss'

type ServiceCardProps ={
    title: string,
    secondTitle: string,
    description: string,
    image: string,
    alt: string,
    classh1: string,
    classh2: string,
}

export default function DigitalMservicesCard({title, secondTitle, description, image,alt,classh1,classh2}:ServiceCardProps) {

    return (
        <section className='dm-ServicesCard'>

            <div className="dm-card">
                <h1 className={classh1}>{title}</h1>
                <div className='dm-clip' >
                    <h2 className={classh2}>{secondTitle}</h2>
                    <p>{description}</p>
                    {/*<span className='default-img'>*/}
                    {/*    <img src={image} alt={alt} loading="lazy"/>*/}
                    {/*</span>*/}

                </div>

            </div>
        </section>
    )
}